import Nav from "./../../components/Nav";
import Footer from "./../../components/Footer";
import { TextContext } from "./../../context/textContext";
import { useContext } from "react";
import s from "./style.module.css";
import cn from "classnames";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const DiscountPageUa = () => {
  const context = useContext(TextContext);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Знижки -10% -20%. Даємо знижки за докладні відгуки про наш олії</title>
        <meta name="description" content="В якості подяки за приділений нам час, ми хотіли б запропонувати вам знижку на ваше замовлення" />
        <meta name="theme-color" content="#efd8b4" />
      </Helmet>
      <main className="about page content">

        <div className={cn(s.banner, s.parallax, s.white)}>
          <div>
            <h1>Знижки -10% -20%</h1>
            <p className={s.subtitle}>
              <b>даємо знижки за докладні відгуки про наш олії</b>
            </p>
            <br />
            <a onClick={context.scroll} className={cn("button", s.big_button, s.bgbeige)} href="/discount#">Як отримати знижку ▼</a>
          </div>
        </div>


        <div className="p50 tLeft">
          <p>
            Всім цікаво читати справжні і докладні відгуки <i>(не просто «Норм» або «Все ОК» )</i>.
          </p>

          <p>
            Але, щоб написати розгорнутий відгук, потрібна деяка кількість зусиль і часу.
          </p>

          <p>
            Тому в якості подяки за приділений нам час, ми хотіли б запропонувати вам знижку на ваше замовлення.
          </p>

          <blockquote>
            <p>
              <strong>Знижка - 10%</strong> за детальний відгук, з посиланням на нас в інстаграм або фейсбук, не менше 1000 символів (це менше півсторінки тексту).
            </p>
            <p>
              <strong>Знижка - 20%</strong> за відеовідгук (1 - 3 хв), з посиланням на нас в інстаграм, фейсбук або ютуб.
            </p>
            <p>
              Відгуки, які нам особливо подобаються отримують ще більшу знижку <strong>–50%</strong> (Ми не жадібні :)
            </p>
          </blockquote>

          <p>Зазвичай ми даємо знижку у вигляді персонального промокоду на наступне замовлення. Але, по домовленості, можемо відправити грошима на вашу банківську карту</p>

          <h3>Ви купуєте відгуки?</h3>

          <p>
            Ні :) Ми збираємо справжні, правдиві відгуки, і хочемо дати вам додаткову мотивацію, щоб ви виділили час на його написання.
          </p>

          <p>
            Так ви зможете отримати улюблений вами продукт дешевше. А ми зможемо зібрати справжні відгуки і поділитися вашими щирими емоціями з іншими.
          </p>

          <p>
            Більш того, ми даємо знижки за будь-які відгуки, навіть негативні. Якщо вам щось не сподобалося - напишіть про це нам докладно, і ми вам теж дамо знижку.
          </p>

          <h3>Персональні дані</h3>

          <p>
            Розміщуючи відгук про нас, згідно з описаною вище бонусної програмі, ви даєте згоду на використання відгуку, (включаючи ваше ім'я, прізвище і картинку користувача опубліковані в ваших соцмережах) в наших публікаціях і рекламних матеріалах.</p>
          <p>
            Якщо ви хотіли б, щоб ми не вказували ваші дані, напишіть про це нам - ми зробимо так як ви захочете.
          </p>

        </div>


      </main>
    </HelmetProvider>
  );
};


const DiscountPageRu = () => {
  const context = useContext(TextContext);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Скидки -10% -20% даем скидки за подробные отзывы о нашем масле</title>
        <meta name="description" content="В качестве благодарности за уделенное нам время, мы хотели бы предложить вам скидки на всю нашу продукцию." />
        <meta name="theme-color" content="#efd8b4" />
      </Helmet>
      <main className="about page content">

        <div className={cn(s.banner, s.parallax, s.white)}>
          <div>
            <h1>Скидки -10% -20%</h1>
            <p className={s.subtitle}>
              <b>даем скидки за подробные отзывы о нашем масле</b>
            </p>
            <br />
            <a onClick={context.scroll} className={cn("button", s.big_button, s.bgbeige)} href="/about#">Как получить скидку ▼</a>
          </div>
        </div>


        <div className="p50 tLeft">
          <p>
            Всем интересно читать настоящие и подробные отзывы <i>(не просто «Норм» или «Все ОК»)</i>.
          </p>

          <p>
            Но, чтобы написать развернутый отзыв, требуется некоторое количество усилий и времени.
          </p>

          <p>
            Поэтому в качестве благодарности за уделенное нам время, мы хотели бы предложить вам скидку на ваш заказ.
          </p>

          <blockquote>
            <p>
              <strong>Скидка - 10%</strong> за подробный отзыв, со ссылкой на нас в инстаграм или фейсбук не менее 1000 символов (это меньше полстранички текста).
            </p>
            <p>
              <strong>Скидка - 20%</strong> за видеоотзыв (1 - 3 мин), со ссылкой на нас в инстаграм, фейсбук или ютуб
            </p>
            <p>
              Отзывы, которые нам особенно нравятся получают еще большую скидку <strong>–50%</strong> (мы не жадные :)
            </p>
          </blockquote>

          <p>Обычно мы даем скидку в виде персонального промокода на следующий заказ. Но, по договоренности, можем отправить деньгами на вашу банковскую карту</p>

          <h3>Вы покупаете отзывы?</h3>

          <p>
            Нет :) Мы собираем настоящие, правдивые отзывы, и хотим дать вам дополнительную мотивацию, чтобы вы выделили время на его написание.
          </p>

          <p>
            Так вы сможете получить  любимый вами продукт дешевле. А мы сможем собрать настоящие отзывы и поделиться вашими искренними эмоциями с другими.
          </p>

          <p>
            Более того, как мы даем скидки за любые отзывы, даже отрицательные. Если вам что-то не понравилось – напишите об этом нам подробно, и мы вам тоже дадим скидку.
          </p>

          <h3>Персональные данные</h3>

          <p>
            Размещая отзыв о нас, согласно описанной выше бонусной программе, вы даете согласие на использование вашего отзыва, (включая ваше имя, фамилию и картинку пользователя опубликованные в ваших соцсетях) в наших публикациях и рекламных материалах.</p>
          <p>
            Если вы хотели бы, чтобы мы не указывали ваши данные, напишите об этом нам – мы сделаем так как вы захотите.
          </p>

        </div>


      </main>
    </HelmetProvider>
  );
};




function DiscountPage() {
  const context = useContext(TextContext);

  if (context.ua) {
    return (
      <>
        <Nav />
        <DiscountPageUa />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Nav />
        <DiscountPageRu />
        <Footer />
      </>
    );
  }
}

export default DiscountPage;
